<template>
    <!-- マニフェスト修正承認依頼モーダル -->
    <div class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">マニフェストの修正</h5>
          <span
            class="c-modal__head__close"
            @click="onClose"
          ></span>
        </div>
        <div class="c-modal__body scroll">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>修正前</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols onTop l-area">
                <div v-if="manifestEditRequest.beforeManifestFixInfo">
                  <dl class="c-dl-w10">
                    <dt>引き渡し日</dt>
                    <dd>
                      {{
                        manifestEditRequest.beforeManifestFixInfo.collectionDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>登録担当者</dt>
                    <dd>
                      {{ manifestEditRequest.beforeManifestFixInfo.registMember ? manifestEditRequest.beforeManifestFixInfo.registMember.name : "" | blankText }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>引き渡し担当者</dt>
                    <dd>
                      {{
                      manifestEditRequest.beforeManifestFixInfo.generateMember ? manifestEditRequest.beforeManifestFixInfo.generateMember.name : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>連絡番号</dt>
                    <dd>
                      <div v-for="contactNo in beforeContactNos" v-bind:key="contactNo">{{ contactNo }}</div>
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>廃棄物数量</dt>
                    <dd>
                      {{
                      manifestEditRequest.beforeManifestFixInfo.wasteQuantity ? `${manifestEditRequest.beforeManifestFixInfo.wasteQuantity}${manifestEditRequest.beforeManifestFixInfo.wasteUnitType.name}` : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>数量確定者</dt>
                    <dd>
                      {{
                      manifestEditRequest.beforeManifestFixInfo.quantityConfirmBusinessType ? manifestEditRequest.beforeManifestFixInfo.quantityConfirmBusinessType.name : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>荷姿数量</dt>
                    <dd>
                      {{
                      manifestEditRequest.beforeManifestFixInfo.shapeQuantity ? `${manifestEditRequest.beforeManifestFixInfo.shapeQuantity}${manifestEditRequest.beforeManifestFixInfo.shapeType ? manifestEditRequest.beforeManifestFixInfo.shapeType.name : ""}` : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>備考</dt>
                    <dd>
                      <div v-for="remarks in beforeRemarks" v-bind:key="remarks">
                        <div v-for="remark in getRemarksLine(remarks)" v-bind:key="remark">{{remark}}</div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>修正後</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols onTop l-area">
                <div v-if="manifestEditRequest.afterManifestFixInfo">
                  <dl class="c-dl-w10">
                    <dt>引き渡し日</dt>
                    <dd>
                      {{
                        manifestEditRequest.afterManifestFixInfo.collectionDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>登録担当者</dt>
                    <dd>
                      {{ manifestEditRequest.afterManifestFixInfo.registMember ? manifestEditRequest.afterManifestFixInfo.registMember.name : "" | blankText }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>引き渡し担当者</dt>
                    <dd>
                      {{
                      manifestEditRequest.afterManifestFixInfo.generateMember ? manifestEditRequest.afterManifestFixInfo.generateMember.name : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>連絡番号</dt>
                    <dd>
                      <div v-for="contactNo in afterContactNos" v-bind:key="contactNo">{{contactNo}}</div>
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>廃棄物数量</dt>
                    <dd>
                      {{
                      manifestEditRequest.afterManifestFixInfo.wasteQuantity ? `${manifestEditRequest.afterManifestFixInfo.wasteQuantity}${manifestEditRequest.afterManifestFixInfo.wasteUnitType.name}` : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>数量確定者</dt>
                    <dd>
                      {{
                      manifestEditRequest.afterManifestFixInfo.quantityConfirmBusinessType ? manifestEditRequest.afterManifestFixInfo.quantityConfirmBusinessType.name : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>荷姿数量</dt>
                    <dd>
                      {{
                      manifestEditRequest.afterManifestFixInfo.shapeQuantity ? `${manifestEditRequest.afterManifestFixInfo.shapeQuantity}${manifestEditRequest.afterManifestFixInfo.shapeType ? manifestEditRequest.afterManifestFixInfo.shapeType.name : ""}` : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl-w10">
                    <dt>備考</dt>
                    <dd>
                      <div v-for="remarks in afterRemarks" v-bind:key="remarks">
                        <div v-for="remark in getRemarksLine(remarks)" v-bind:key="remark">{{remark}}</div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
            <div class="full-w">
              <ErrorMessage :errMsgs="modalErrMsgs" />
              <div class="l-flex">
                <div class="l-flex cols u-space-btwn">
                  <div class="c-radio">
                    <input
                      id="approve-true"
                      type="radio"
                      name="approve"
                      :value="true"
                      v-model="isApproved"
                    />
                    <label class="c-radio__label" for="approve-true">
                      <span class="c-radio__box"></span>
                      承認
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="approve-false"
                      type="radio"
                      name="approve"
                      :value="false"
                      v-model="isApproved"
                    />
                    <label class="c-radio__label" for="approve-false">
                      <span class="c-radio__box"></span>
                      否認
                    </label>
                  </div>
                </div>
              <div
                class="c-btn primary small l-flex__right"
                @click.once="onClickApproveButton"
                :class="{ disabled: isDisabled || isJwnetReferring }"
              >
                決定
              </div>
              </div>
            </div>
            <div v-if="!isApproved" class="full-w">
              <MyTextarea 
                :value.sync="denyReason"
                rows="6"
                placeholder="否認理由を入力してください"
                :validation="validateJustBlank"
              />
            </div>
        </div>
      </div>
    </div>
    <!-- .c-modal -->
</template>

<style lang="scss" scoped>
  .full-w {
    width: 100%;
  }
  .foot_local {
    flex-direction: column;
  }
</style>

<script>
import MyTextarea from "@/components/parts/input/MyTextarea";
import validation from "@/mixin/validation";
import commonApi from "@/mixin/commonApi";


export default {
  name: "manifest-fix-approve-modal",
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    onApprove: {
      type: Function,
      default: () => {},
    },
    onDenial: {
      type: Function,
      default: () => {},
    },
    manifestEditRequest: {
      type: Object,
    },
    modalErrMsgs: {
      type: Array
    },
    isJwnetReferring: {
      type: Boolean,
    }
  },
  components: {
    MyTextarea,
  },
  mixins: [
    validation,
    commonApi,
  ],
  computed: {
    isDisabled() {
      if (this.isApproved) {
        return false;
      }

      if (this.denyReason) {
        return false;
      }

      return true;
    },
    beforeContactNos() {
      return this.getContactNos(this.manifestEditRequest.beforeManifestFixInfo);
    },
    afterContactNos() {
      return this.getContactNos(this.manifestEditRequest.afterManifestFixInfo);
    },
    beforeRemarks() {
      return this.getRemarks(this.manifestEditRequest.beforeManifestFixInfo);
    },
    afterRemarks() {
      return this.getRemarks(this.manifestEditRequest.afterManifestFixInfo);
    },
  },
  data() {
    return {
      isApproved: false,
      denyReason: null,
    };
  },
  methods: {
    getContactNos(manifestInfo) {
      if (manifestInfo.contactNoInfo == null) {
        return "";
      }
      const contactNos = manifestInfo.contactNoInfo;

      if (contactNos.length === 0) {
        return "";
      }

      return contactNos.map((contactNo) => contactNo.contactNo);
    },
    getRemarks(manifestInfo) {
      if (manifestInfo.remarksInfo == null) {
        return "";
      }

      const remarks = manifestInfo.remarksInfo;

      if (remarks.length === 0) {
        return "";
      }

      return remarks.map((remarks) => remarks.detail);
    },
    getRemarksLine(remarks) {
      return remarks.split("\n");
    },
    onClickApproveButton() {
      if (this.isApproved) {
        this.onApprove();
      } else {
        this.onDenial(this.denyReason);
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.c-dl-w10 {
  display: flex;

  dt {
    width: 10rem;
  }

  dd {
    margin-left: 1rem;
  }
}
</style>