<template>
  <div class="l-contents l-box">
    <div class="l-box title-wrap">
      <h3 class="c-lead icon">最終処分終了報告</h3>
    </div>
    <div v-if="disposalReportDisplayType.AfterReport === type">
      <div
          v-for="(disposalSite, i) in finalDisposalSiteList" :key="'site-' + i">
        <div class="c-inputWrap c-infoList__cell sub-title c-tooltip">
          <label>最終処分場 {{ i + 1 }}</label>
          <div class="c-status info-red"
               v-if="isFinalDisposalSiteNG && disposalSite.isFinalDisposalSiteCheckOK === false"
          >
            <div class="c-infoList__popup">
              <p>最終処分場チェックでエラーです</p>
            </div>
          </div>
        </div>
        <div
            v-for="(label, index) in finalDisposalSiteLabels"
            :key="'site-' + i + '-label-' + index"
            class="c-inputWrap"
        >
          <div class="c-inputWrap__label">
            <label>{{ label }}</label>
          </div>
          <div v-if="index === 0" class="c-inputWrap__items">
            {{ disposalSite.finalDisposalReportDate ? disposalSite.finalDisposalReportDate : "" | dateJpYMDFormat }}
          </div>
          <div v-if="index === 1" class="c-inputWrap__items">
            {{ disposalSite.finalDisposalSiteName ? disposalSite.finalDisposalSiteName : "" | blankText }}
          </div>
          <div v-if="index === 2" class="c-inputWrap__items">
            {{ disposalSite.finalDisposalSiteZipCode ? '〒' + disposalSite.finalDisposalSiteZipCode : "" | blankText }}<br />
            {{ disposalSite.finalDisposalSiteAddress1 ? disposalSite.finalDisposalSiteAddress1 : "" }}
            {{ disposalSite.finalDisposalSiteAddress2 ? disposalSite.finalDisposalSiteAddress2 : "" }}
            {{ disposalSite.finalDisposalSiteAddress3 ? disposalSite.finalDisposalSiteAddress3 : "" }}
            {{ disposalSite.finalDisposalSiteAddress4 ? disposalSite.finalDisposalSiteAddress4 : "" }}
          </div>
          <div v-if="index === 3" class="c-inputWrap__items">
            {{ disposalSite.finalDisposalSiteTel ? disposalSite.finalDisposalSiteTel : "" | blankText }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="disposalReportDisplayType.SelfReportOrNotNeedReport === type">
      <div class="c-inputWrap">
        <div class="c-inputWrap__label">
          <label>最終処分終了日</label>
        </div>
        <div class="c-inputWrap__items">
          {{ finalDisposalReportDate ? finalDisposalReportDate : "" | dateJpYMDFormat }}
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="wasteType && (wasteType.id === wasteTypeDomain.VALUABLES || wasteType.id === wasteTypeDomain.COMMON_WASTE)"><p>-</p></div>
      <div v-else><p>未報告</p></div>
    </div>
  </div>
</template>

<script>
import { DOMAIN } from "@/const/index";
export default {
  props: {
    wasteType:{
      type: Object,
    },
    finalDisposalSiteList: {
      type: Array,
    },
    type: {
      type: Number,
    },
    isFinalDisposalSiteNG:{
      type: Boolean
    },
    finalDisposalReportDate: {
      type: String
    }
  },
  data() {
    return {
      wasteTypeDomain: DOMAIN.WASTE_TYPE,
      disposalReportDisplayType: DisposalReportDisplayType,
      finalDisposalSiteLabels: [
        "最終処分終了日",
        "最終処分事業場名",
        "最終処分事業場住所",
        "最終処分事業場電話番号",
      ],
    }
  }
};

const DisposalReportDisplayType = {
  BeforeReport: 1,
  AfterReport: 2,
  SelfReportOrNotNeedReport: 3,
};

export { DisposalReportDisplayType };
</script>
<style scoped>
.sub-title {
  border-bottom: 1px solid #cbd0d8;
  margin: 2rem 0;
  font-size: 1.6rem;
}
</style>