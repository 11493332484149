export default {
  methods: {
    // テキストエリア追加
    addTextareaHandler() {
      if (this.formValue.remarksInfo.length >= 5) return;
      let newId;
      if (
        this.formValue.remarksInfo == null ||
        this.formValue.remarksInfo.length === 0
      ) {
        newId = 1;
      } else {
        newId =
          this.formValue.remarksInfo[this.formValue.remarksInfo.length - 1].id +
          1;
      }
      this.formValue.remarksInfo.push({
        id: newId,
        detail: "",
      });
      this.validate.remarks.push({
        isValid: true,
        errMsg: ""
      });
    },
    // テキストエリア削除
    removeTextareaHandler(id) {
      this.formValue.remarksInfo = this.formValue.remarksInfo.filter(
        (ta) => ta.id != id
      );
      this.validate.remarks.pop();
    },
    // テキストエリアのchange処理
    onChangeTextareaHandler() {
      this.formValue.remarksInfo.remarksList = this.formValue.remarksInfo.map(
        (tsInfo) => {
          return {
            detail: tsInfo.detail,
          };
        }
      );
    },
  },
};
