export default {
  methods: {
    // 連絡番号追加
    addContactNoHandler() {
      if (this.formValue.contactNoInfo.length >= 3) return;
      this.formValue.contactNoInfo.push({
        contactNo: "",
      });

      //バリデーションも追加
      this.validate.contactNo = [
        ...this.validate.contactNo,
        { isValid: true, errMsg: "" },
      ];
    },
    // 連絡番号削除
    removeContactNoHandler(contactNo, index) {
      this.formValue.contactNoInfo = this.formValue.contactNoInfo.filter(
        (cn) => cn != contactNo
      );

      //バリデーションも削除
      this.validate.contactNo = this.validate.contactNo.filter(
        (_cv, i) => i != index
      );
    },
  },
};
