export default {
  data() {
    return {
      formValue: {
        manifestInfo: {
          manifestNo: 123,
          manifestStatusInfo: {
            id: null,
            name: "",
          },
          collectionDate: "",
          collectionTime: "",
          issueDate: "",
          generateCompanyInfo: {
            id: null,
            name: "",
          },
          generateStoreInfo: {
            id: null,
            name: "",
          },
          registMember: {
            id: null,
            name: "",
          },
          generateMember: {
            id: null,
            name: "",
          },
          manifestUpdateDate: "",
          routeId: null,
          conversionFactorKg: null,
          conversionFactorM3: null,
          conversionFactorLiter: null,
          conversionFactorUnitNumber: null,
          attachedFilePath: "",
          attachedFileName: "",
        },
        routeInfo: {
          routeId: null,
          generateStore: {
            id: null,
            name: "",
          },
          collectCompanys: [
            {
              id: null,
              name: "",
            },
          ],
          disposalSite: {
            id: null,
            name: "",
          },
          jwnetItem: {
            id: null,
            name: "",
            isSpecial: false,
            isHarmful: false,
          },
          wdsFileName: "",
        },
        labelInfo: {
          manifestLabels: [
            {
              labelId: null,
              labelHeader: "",
              labelTypeId: null,
              labelValue: "",
              labelUnit: "",
            },
          ],
        },
        wasteInfo: {
          wasteName: {
            id: null,
            name: "",
          },
          detailWasteName: null,
          wasteQuantity: null,
          wasteUnitType: {
            id: null,
            name: "",
          },
          quantityConfirmBusinessType: {
            id: null,
            name: "",
          },
          shapeQuantity: null,
          shapeType: {
            id: null,
            name: "",
          },
          collectUnitPrice: null,
          collectPriceWasteUnitType: {
            id: null,
            name: "",
          },
          disposalWayType: {
            id: null,
            name: "",
          },
          disposalUnitPrice: null,
          disposalPriceWasteUnitType: {
            id: null,
            name: "",
          },
          wasteNameId: null,
          wasteUnitTypeId: null,
          quantityConfirmBusinessTypeId: null,
          quantityConfirmBusinessTypeName: "",
          shapeTypeId: null,
          shapeTypeName: "",
          disposalWayTypeId: null,
        },
        contactNoInfo: [
          {
            id: null,
            contactNo: "",
          },
        ],
        collectCost: null,
        disposalCost: null,
        valuablesCost: null,
        remarksInfo: [
          {
            id: null,
            detail: "",
          },
        ],
        harmfulList: [
          {
            id: null,
            name: "",
          },
        ],
        anyGenerateStoreInfo: {
          anyGenerateStoreName: "",
          anyGenerateStoreAddress: {
            zipCode: "",
            prefecturesId: "",
            address: "",
            buildingName: "",
          },
        },
        collectReportList: [
          {
            collectionReportDate: "",
            section: null,
            collectCompany: {
              legalPersonalityTypeId: null,
              legalPersonalityTypeName: "",
              companyFullName: "",
              id: null,
              name: "",
            },
            collectMemberInfo: {
              id: null,
              name: "",
            },
            reportMemberInfo: {
              id: null,
              name: "",
            },
            collectWay: {
              id: null,
              name: "",
            },
            carNo: "",
            collectQuantity: null,
            collectWasteUnitType: {
              id: null,
              name: "",
            },
            valuablesQuantity: null,
            valuablesUnitType: {
              id: null,
              name: "",
            },
            collectFactoryInfo: {
              id: null,
              name: "",
            },
            destinationInfo: {
              destinationTypeInfo: {
                id: null,
                name: "",
              },
              id: null,
              name: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: null,
                address: "",
                buildingName: "",
              },
            },
            remark: "",
            collectManifestStatusInfo: {
              id: null,
              name: null,
            },
            collectReportedWayTypeId: '',
          },
        ],
        disposalReportInfo: {
          wasteReceiptDate: "",
          disposalReportDate: "",
          disposalCompany: {
            legalPersonalityTypeId: null,
            legalPersonalityTypeName: "",
            companyFullName: "",
            id: null,
            name: "",
          },
          disposalMemberName: "",
          reportMemberName: "",
          disposalWayType: {
            id: null,
            name: "",
          },
          acceptQuantity: null,
          acceptWasteUnitType: {
            id: null,
            name: "",
          },
          remark: "",
          isSelfReportOrNotNeedReport: false,
        },
        finalDisposalReportInfo: {
          finalDisposalSiteName: "",
          finalDisposalSiteZipCode: "",
          finalDisposalSiteAddress1: "",
          finalDisposalSiteAddress2: "",
          finalDisposalSiteAddress3: "",
          finalDisposalSiteAddress4: "",
          finalDisposalSiteTel: "",
          finalDisposalReportDate: "",
          isFinalDisposalSiteCheckOK: null,
        },
        isEdittable: false,
        edittableErrorMessage: "",
        isNotCreatedByMemberForCollect: false,
        isFinalDisposalSiteCheckOK: null,
        isFinalDisposalSiteNG: false,
        isCollectReportable: false,
        isCollectReportFixable: false,
        directInput: "",
      },
    };
  },
};
