<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="マニフェスト" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <template v-if="!isFetching">
        <ManifestDetailProxyReserveDenial
          v-if="isManifestProxyReserveDenial"
          :manifestId="manifestId"
          :formValue="formValue"
          :afterUpdateFunc="getManifestInfo"
        />

        <!-- 交付前 statusId 0 - 19 かつ 操作権限ありマニフェスト -->
        <ManifestDetailBeforeIssue
          v-else-if="isBeforeIssue && (this.isOwnedManifest == true || this.$store.getters['userInfo/isGenerator'])"
          :manifestId="manifestId"
          :isOwnedManifest="isOwnedManifest"
          :formValue="formValue"
          :afterUpdateFunc="getManifestInfo"
        />
        <!-- 進行中 statusId 20 - 79 または 操作権限なしマニフェスト -->
        <ManifestDetailAfterIssue
          v-else-if="(isInProgress || isOwnedManifest == false || this.$store.getters['userInfo/isDisposer'])"
          :manifestId="manifestId"
          :isOwnedManifest="isOwnedManifest"
          :isFirstCollectCompany="isFirstCollectCompany"
          :formValue="formValue"
          :afterUpdateFunc="getManifestInfo"
        />
        <!-- 完了 statusId 80 or 90 or 91 -->
        <ManifestDetailAfterIssue
          v-else-if="isCompleted"
          :manifestId="manifestId"
          :formValue="formValue"
          :afterUpdateFunc="getManifestInfo"
        />
      </template>

    </main>
    <AppFooter />
  </div>
</template>

<script>
import { DOMAIN } from "@/const/index.js"
import ManifestDetailProxyReserveDenial from "./components/ManifestDetailProxyReserveDenial";
import ManifestDetailBeforeIssue from "./components/ManifestDetailBeforeIssue";
import ManifestDetailAfterIssue from "./components/ManifestDetailAfterIssue";
import formValue from "./mixins/formValue";
import callApi from "./mixins/callApi";
import route from "./mixins/route";


const Status = {
  Fetching: -1,
  BeforeIssue: 0,
  InProgress: 1,
  Completed: 2,
};

export default {
  name: "manifest-detail",
  components: {
    ManifestDetailProxyReserveDenial,
    ManifestDetailBeforeIssue,
    ManifestDetailAfterIssue,
  },
  mixins: [formValue, callApi, route],
  data() {
    return {
      manifestId: null,
      isOwnedManifest: null,// 自身で操作可能なマニフェストかどうか
      isFirstCollectCompany: false, // 自身がルートの第1区間運搬事業者かどうか
    };
  },
  methods: {
    getManifestStatus() {
      if (this.formValue.manifestInfo.manifestStatusInfo.id == null) {
        return Status.Fetching;
      }
      const statusId = +this.formValue.manifestInfo.manifestStatusInfo.id;
      if (statusId < DOMAIN.MANIFEST_STATUS.UNDER_ISSUE) {
        return Status.BeforeIssue;
      } else if (statusId >= DOMAIN.MANIFEST_STATUS.UNDER_ISSUE && statusId < DOMAIN.MANIFEST_STATUS.COMPLETE) {
        return Status.InProgress;
      } else if (statusId === DOMAIN.MANIFEST_STATUS.COMPLETE || 
        statusId === DOMAIN.MANIFEST_STATUS.DELETE || 
        statusId === DOMAIN.MANIFEST_STATUS.UNDER_DELETE) {
        return Status.Completed;
      }
      throw new Error('想定外のマニフェストステータスです');
    },
    getManifestInfo(manifestId) {
      this.manifestId = manifestId;
      return this.getManifestInfoApi(manifestId);
    }
  },

  computed: {
    isManifestProxyReserveDenial() {
      return +this.formValue.manifestInfo.manifestStatusInfo.id === DOMAIN.MANIFEST_STATUS.RESERVE_DENIAL;
    },
    isBeforeIssue() {
      return this.getManifestStatus() === Status.BeforeIssue;
    },
    isInProgress() {
      return this.getManifestStatus() === Status.InProgress;
    },
    isCompleted() {
      return this.getManifestStatus() === Status.Completed;
    },
    isFetching() {
      return this.getManifestStatus() === Status.Fetching
    }
  },

  mounted() {
    // 初期表示に必要なAPIを呼ぶ
    this.getManifestInfo(+this.$route.params.id);
  },
};
</script>
