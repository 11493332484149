<template>
    <!--ルート選択モーダル-->
    <div class="c-modal xlg" v-if="isRouteSelectModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">ルート設定</h5>
          <span
            class="c-modal__head__close"
            @click="$emit('onClickCloseButton')"
          ></span>
        </div>
        <div class="c-modal__body">

          <!--検索条件開始-->
          <div class="c-inputWrap">
            <div>
              <span class="text-label">廃棄物種類</span>
              <Selectbox
                  class="mid"
                  :value.sync="routeModalSearchParam.jwnetItemTypeId"
                  :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                    v-for="(opt, index) in jwnetItemsOpt"
                    :key="`jwnet-opt-${index}`"
                    :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                    class="xlg"
                    :value.sync="routeModalSearchParam.routeName"
                    inputType="text"
                    placeholder="ルート名称を入力"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">廃棄物名称</span>
                <InputText
                    class="xlg"
                    :value.sync="routeModalSearchParam.wasteName"
                    inputType="text"
                    placeholder="廃棄物名称を入力"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                </InputText>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">運搬事業者</span>
                <v-autocomplete ref="collect_company_autocomplete" @change="s => {onChange(s, 'collect_company_autocomplete')}" placeholder="排出事業者名称を入力" class="c-input md_l" :items="this.selectCollectCompanyList" :min-len="0" :wait="300" @update-items='updateCollectCompanyList' v-model="selectedCollectCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分事業者</span>
                <v-autocomplete ref="disposal_company_autocomplete" @change="s => {onChange(s, 'disposal_company_autocomplete')}" placeholder="処分事業者名称を入力" class="c-input md_l" :items="this.selectDisposalCompanyList" :min-len="0" :wait="300" @update-items='updateDisposalCompanyList' v-model="selectedDisposalCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分場</span>
                <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="処分場名称を入力" class="c-input md_l" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                </v-autocomplete>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <!--廃棄物区分条件-->
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt" :key="'wasteTypeId-' + wtIndex">
                    <div class="c-checkbox">
                      <input
                          :id="'wasteTypeId-' + wtIndex"
                          type="checkbox"
                          v-model="routeModalSearchParam.wasteTypeIdList"
                          :value="wasteTypeId.id"
                          :disabled="isEmptyRegistOrNotSetRoute"
                      />
                      <label class="c-checkbox__label" :for="'wasteTypeId-' + wtIndex">
                        <span class="c-checkbox__box"></span>
                        {{ wasteTypeId.name }}
                      </label>

                      <!--産業廃棄物区分の場合-->
                      <template v-if=" wasteTypeId.id === wasteTypeIdOpt[0].id && routeModalSearchParam.wasteTypeIdList.includes(wasteTypeId.id)">
                        <div class="l-flex wrap full" >
                          <span>(</span>
                          <div class="c-input" v-for="(industrialWasteType, industrialWasteTypeIndex) in industrialWasteJwnetTypeOpt" :key="'industrialWasteType-' + industrialWasteTypeIndex">
                            <input
                                :id="'industrialWasteType-' + industrialWasteTypeIndex"
                                type="checkbox"
                                v-model="routeModalSearchParam.industrialWasteJwnetTypeIdList"
                                :value="industrialWasteType.id"
                            />
                            <label class="c-checkbox__label" :for="'industrialWasteType-' + industrialWasteTypeIndex">
                              <span class="c-checkbox__box"></span>
                              {{ industrialWasteType.name }}
                            </label>
                          </div>
                          <span>)</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!--マニフェスト区分条件-->
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(manifestType, manifestTypeIndex) in manifestTypeOpt" :key="'manifestType-' + manifestTypeIndex">
                    <div class="c-checkbox">
                      <input
                          :id="'manifestType-' + manifestTypeIndex"
                          type="checkbox"
                          v-model="routeModalSearchParam.manifestTypeIdList"
                          :value="manifestType.id"
                          :disabled="isEmptyRegistOrNotSetRoute"
                      />
                      <label class="c-checkbox__label" :for="'manifestType-' + manifestTypeIndex">
                        <span class="c-checkbox__box"></span>
                        {{ manifestType.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div>
              <span class="text-label">お気に入り</span>
              <div class="l-flex cols">
                <div class="c-input">
                  <div class="c-checkbox">
                    <input
                        id="is-favorite"
                        type="checkbox"
                        v-model="routeModalSearchParam.isFavorite"
                    />
                    <label class="c-checkbox__label" for="is-favorite">
                      <span class="c-checkbox__box"></span>
                      有
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="l-container">
            <div class="l-btns l-block-lg">
              <div class="c-btn secondary small" @click="resetHandler">
                条件をリセット
              </div>
              <div class="c-btn primary small"  @click="$emit('routeModalSearchHandler')">検索</div>
            </div>
          </div>
          <!--検索条件終了-->
          <!--検索結果表示開始-->
          <div class="c-infoList__row head">
            <div class="c-infoList__cell xsm"></div>
            <div class="c-infoList__cell md">ルート名称</div>
            <div class="c-infoList__cell md">廃棄物区分</div>
            <div class="c-infoList__cell md">廃棄物種類</div>
            <div class="c-infoList__cell md">廃棄物名称</div>
            <div class="c-infoList__cell md">運搬事業者</div>
            <div class="c-infoList__cell md">処分場</div>
          </div>
          <div
            v-for="item of narrowedRoutesList"
            :key="item.routeId"
            class="c-infoList__row list"
          >
            <div class="c-infoList__cell xsm">
              <div class="c-radio">
                <input
                  :id="'route' + item.routeId"
                  type="radio"
                  :value="item.routeId"
                  v-model="selectedRoute"
                />
                <label class="c-radio__label" :for="'route' + item.routeId">
                  <span class="c-radio__box"></span>
                </label>
              </div>
            </div>
            <div class="c-infoList__cell md">{{ item.routeName }}</div>
            <div class="c-infoList__cell md">{{ item.wasteType.name }}/{{ item.manifestType.id === 1 ? "電子" : "紙" }}</div>
            <div class="c-infoList__cell md">{{ item.jwnetItemType.name }}</div>
            <div class="c-infoList__cell md">{{ item.jwnetItemType.wasteName }}</div>
            <div class="c-infoList__cell md">
              <CommaText :items="item.collectCompanys" arg="name" :maxLength="15" />
            </div>
            <div class="c-infoList__cell md">
              <div class="c-text vertical_dot">{{ item.disposalSite.name + " JWNETID:" + item.jwnetId }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn primary small"
            @click="$emit('clickSetRoute', selectedRoute)"
          >
            OK
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import common from "@/mixin/common";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import CommaText from "@/components/parts/CommaText";
import validation from "@/mixin/validation";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";
export default {
  props: {
    isRouteSelectModalShow: Boolean,
    narrowedRoutesList: Array,
    jwnetItemsOpt: Array,
    wasteTypeIdOpt: Array,
    industrialWasteJwnetTypeOpt: Array,
    manifestTypeOpt: Array,
    routeModalSearchParam: Object,
    //空登録または、ルート未設定で予約されたマニフェストであるか
    isEmptyRegistOrNotSetRoute: Boolean,
    selectCollectCompanyList: Array, //入力テキストで絞る運搬事業者選択肢
    selectDisposalCompanyList: Array, //入力テキストで絞る処分事業者選択肢
    selectDisposalSiteList: Array, //入力テキストで絞る処分場選択肢
    collectCompanyList: Array,
    disposalCompanyList: Array,
    disposalSiteList: Array,
  },
  components: {
    InputText,
    Selectbox,
    CommaText,
  },
  mixins: [
    common,
    validation,
  ],

  data() {
    return {
      selectedRoute: null,
      selectedCollectCompany: '', //選択した運搬事業者
      selectedDisposalCompany: '', //選択した処分事業者
      selectedDisposalSite: '', //選択した処分場
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
    }
  },
  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },
    updateCollectCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectCompanyCompanyList = this.collectCompanyList;
        return
      }
      this.selectCompanyCompanyList = this.collectCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectDisposalCompanyList = this.disposalCompanyList;
        return
      }
      this.selectDisposalCompanyList = this.disposalCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList;
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
    resetHandler() {
      this.selectedCollectCompany = ''
      this.selectedDisposalCompany = ''
      this.selectedDisposalSite = ''
      this.$emit('resetRouteModalSearchHandler')
    },
  },
  watch: {
    selectedCollectCompany: function (newVal) {
      this.routeModalSearchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalCompany: function (newVal) {
      this.routeModalSearchParam.disposalCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalSite: function (newVal) {
      this.routeModalSearchParam.disposalSiteId = newVal ? newVal.id : ''
    },
  },
};
</script>
