export default {
  methods: {
    // 有害物質名追加
    addHarmfulTypeHandler() {
      this.formValue.harmfulList.push({
        id: null,
        name: "",
      });
    },
    // 有害物質名削除
    removeHarmfulTypeHandler(harmfulType) {
      this.formValue.harmfulList = this.formValue.harmfulList.filter(
        (ht) => ht != harmfulType
      );
    },
  },
};
