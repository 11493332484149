<template>
  <div class="c-manifest__progress">
    <div class="c-manifest__progress__each" :class="{ done: manifestStatusId }">
      <div class="c-manifest__progress__each__circle">
        <span>予約</span>
      </div>
      <span
        v-if="manifestStatusId < 20"
        class="c-manifest__progress__each__icon ticket"
      ></span>
    </div>
    <div
      class="c-manifest__progress__each"
      :class="{ done: manifestStatusId >= 20 }"
    >
      <div class="c-manifest__progress__each__circle">
        <span>交付</span>
      </div>
      <span
        v-if="manifestStatusId >= 20 && manifestStatusId < 22"
        class="c-manifest__progress__each__icon truck"
      ></span>
    </div>
    <div
      class="c-manifest__progress__each"
      :class="{ done: manifestStatusId >= 22 }"
    >
      <div class="c-manifest__progress__each__circle">
        <span>処理中</span>
      </div>
      <span
        v-if="manifestStatusId >= 22 && manifestStatusId < 50"
        class="c-manifest__progress__each__icon truck"
      ></span>
    </div>
    <div
      class="c-manifest__progress__each"
      :class="{ done: manifestStatusId >= 50 }"
    >
      <div class="c-manifest__progress__each__circle">
        <span>最終報告</span>
      </div>
      <span
        v-if="manifestStatusId >= 50 && manifestStatusId <= 59"
        class="c-manifest__progress__each__icon truck"
      ></span>
    </div>
    <div
      class="c-manifest__progress__each complete"
      :class="{ done: (manifestStatusId === 80 || manifestStatusId === 90 || manifestStatusId === 91)}"
    >
      <div class="c-manifest__progress__each__circle">
        <span>完了</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "manifest-progress-bar",
  props: {
    manifestStatusId: {
      type: Number,
    },
  },
};
</script>
