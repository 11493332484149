<template>
  <div class="l-contents">
    <div class="l-box">
      <h3 class="c-lead icon">処分終了報告</h3>
    </div>
    <div v-if="type === interDisposalReportDisplayType.AfterReport">
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">廃棄物受領日</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.wasteReceiptDate | dateJpYMDFormat }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">処分終了日</label>
        </div>
        <div class="c-inputWrap__items">
          {{ disposalRegistDate | dateJpYMDFormat }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">処分業者名</label>
        </div>
        <div class="c-inputWrap__items">
          {{
            (list.disposalCompany ? list.disposalCompany.companyFullName : "") | blankText
          }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">処分担当者</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.disposalMemberName | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">報告担当者</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.reportMemberName | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">処分方法</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.disposalWayType ? list.disposalWayType.name : "" | blankText }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">受入量</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.acceptQuantity ? list.acceptQuantity : "" | formatFloat }}
          {{ list.acceptWasteUnitType ? list.acceptWasteUnitType.name : "" }}
        </div>
      </div>
      <div class="c-inputWrap l-area">
        <div class="c-inputWrap__label">
          <label for="">備考</label>
        </div>
        <div class="c-inputWrap__items">
          {{ list.remark ? list.remark : "" | blankText }}
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="wasteType && (wasteType.id === wasteTypeDomain.VALUABLES || wasteType.id === wasteTypeDomain.COMMON_WASTE)"><p>-</p></div>
      <div v-else><p>未報告</p></div>
    </div>
  </div>
</template>

<script>
import { DOMAIN } from "@/const/index";
export default {
  props: {
    wasteType: {
      type: Object,
    },
    list: {
      type: Object,
    },
    type: {
      type: Number,
    },
    disposalRegistDate: {
      type: String,
    }
  },
  data() {
    return {
      interDisposalReportDisplayType: InterDisposalReportDisplayType,
      wasteTypeDomain: DOMAIN.WASTE_TYPE,
    }
  }
};

const InterDisposalReportDisplayType = {
  BeforeReport: 1,
  AfterReport: 2,
};

export { InterDisposalReportDisplayType };
</script>
