import axios from "axios";
import {API_URL} from "@/const";

export default {
    methods: {
        uploadFileApi(file) {
            const params = new FormData();
            params.append("file", file);
            params.append("fileName", file.name);

            return axios
                .post(API_URL.COMMON.FILE_UPLOAD, params, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    return res.data.filePath;
                });
        },
    }
}