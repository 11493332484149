<template>
  <div>
    <div class="l-container l-area">
      <ManifestProgressBar
          class="l-box"
          :manifestStatusId="formValue.manifestInfo.manifestStatusInfo.id"
      />
      <ToastMessage :toastMsg.sync="toastMsg" />
      <ErrorMessage
        :errMsgs="[formValue.edittableErrorMessage]"
        v-if="formValue.isEdittable && formValue.edittableErrorMessage"
      />
      <ErrorMessage :errMsgs="errMsgs" />
      <ErrorMessage v-if="isCollector" :errMsgs="['マニフェストの代理予約が否認されました。予約を行う場合は、新たにマニフェスト代理登録を行ってください。']" />
      <div v-if="isJwnetReferring" class="l-contents">
        <div class="c-status info-red"></div>
        <span>このマニフェストはJWNETへの情報照会中です</span>
        <div class="c-jwnet-alert">
          <span>しばらく待ってからマニフェストを操作して下さい</span>
        </div>
      </div>
      <!-- マニフェスト情報 -->
      <div class="l-contents">
        <div class="l-box">
          <h3 class="c-lead icon">マニフェスト情報</h3>
        </div>
        <div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェスト番号</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ formValue.manifestInfo.manifestNo }}
              </div>
            </div>
          </div>
          <div class="c-inputWrap l-area">
            <div class="c-inputWrap__label">
              <label>引き渡し日</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.manifestInfo.collectionDate | dateJpYMDFormat }}
              {{ formValue.manifestInfo.collectionTime }}
            </div>
          </div>

          <div class="c-accordion">
            <input
              id="manifest-info-acc"
              class="c-accordion__cb"
              type="checkbox"
            />
            <div class="c-accordion__head">
              <label
                class="c-accordion__head__lbl"
                for="manifest-info-acc"
              ></label>
              <div class="c-accordion__head__text">詳細</div>
            </div>
            <div class="c-accordion__body for-form">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>交付日</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ formValue.manifestInfo.issueDate | dateJpYMDFormat }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>排出事業者名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.generateCompanyInfo
                      ? formValue.manifestInfo.generateCompanyInfo.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>排出事業場名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.generateStoreInfo
                      ? formValue.manifestInfo.generateStoreInfo.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{
                        formValue.manifestInfo.registMember
                          ? formValue.manifestInfo.registMember.name
                          : "" | blankText
                      }}
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>引き渡し担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{
                        formValue.manifestInfo.generateMember
                          ? formValue.manifestInfo.generateMember.name
                          : "" | blankText
                      }}
                    </div>
                  </div>
                  <div v-if="formValue.manifestInfo.proxyCollectMember != null" class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>運搬代理登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{ formValue.manifestInfo.proxyCollectMember.name }}
                    </div>
                  </div>
                  <div v-else-if="formValue.manifestInfo.proxyDisposalMember != null" class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>処分代理登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{ formValue.manifestInfo.proxyDisposalMember.name }}
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬費用（総額）</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.collectCost ? formValue.collectCost : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分費用</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.disposalCost
                      ? formValue.disposalCost
                      : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>最終修正日</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.manifestUpdateDate | dateJpYMDFormat
                  }}
                </div>
              </div>
            </div>

            <!-- / c-accordion__body -->
          </div>
          <!-- / c-accordion -->
        </div>
      </div>
      <!-- /.l-contents -->

      <!-- 廃棄物情報 -->
      <div class="l-contents l-area">
        <div class="l-box">
          <h3 class="c-lead icon">廃棄物情報</h3>
        </div>
        <div>
          <div class="c-inputWrap l-area">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.routeInfo.jwnetItem.name }}
            </div>
          </div>

          <div class="c-accordion">
            <input
              id="disposal-info-acc"
              class="c-accordion__cb"
              type="checkbox"
            />
            <div class="c-accordion__head">
              <label
                class="c-accordion__head__lbl"
                for="disposal-info-acc"
              ></label>
              <div class="c-accordion__head__text">詳細</div>
            </div>
            <div class="c-accordion__body for-form">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>ルート</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols onTop l-area">
                    <div>
                      <div
                        v-for="route in narrowedRoutesList"
                        :key="route.routeId"
                      >
                        <div v-if="route.routeId === formValue.routeInfo.routeId">
                          {{ route.routeName }}
                        </div>
                      </div>
                      <div>
                        <dl class="c-dl">
                          <dt>排出事業場：</dt>
                          <dd>
                            {{ routeCompanyName.generateStore | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>運搬業者：</dt>
                          <dd>
                            {{ routeCompanyName.collectCompany | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>処分場：</dt>
                          <dd>
                            {{ routeCompanyName.disposalSite | blankText }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.wasteName
                      ? formValue.wasteInfo.wasteName.name
                      : "" | blankText
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物数量</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.wasteQuantity
                      ? formValue.wasteInfo.wasteQuantity
                      : "" | formatFloat
                  }}
                  {{
                    formValue.wasteInfo.wasteUnitType
                      ? formValue.wasteInfo.wasteUnitType.name
                      : ""
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>数量確定者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.quantityConfirmBusinessType
                      ? formValue.wasteInfo.quantityConfirmBusinessType.name
                      : "" | blankText
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.shapeType
                      ? formValue.wasteInfo.shapeType.name
                      : "" | blankText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿数量</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.shapeQuantity
                      ? formValue.wasteInfo.shapeQuantity
                      : "" | blankText
                  }}
                </div>
              </div>

              <!--運搬単価-->
              <template v-if="formValue.wasteInfo.collectUnitPriceList && formValue.wasteInfo.collectUnitPriceList.length">
                <div
                    v-for="(collectUnitPrice, index) in formValue.wasteInfo.collectUnitPriceList"
                    :key="'collectUnitPrice-' + index"
                    class="c-inputWrap"
                >
                  <div class="c-inputWrap__label">
                    <label>
                      運搬単価（区間{{ collectUnitPrice.sectionNo }}）
                    </label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ collectUnitPrice.collectUnitPrice | moneyText }}
                    {{ collectUnitPrice.collectPriceWasteUnitType ? "/ " + collectUnitPrice.collectPriceWasteUnitType.name : '' }}
                  </div>
                </div>
              </template>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分単価</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.disposalUnitPrice
                      ? formValue.wasteInfo.disposalUnitPrice
                      : "" | moneyText
                  }}
                  {{
                    formValue.wasteInfo.disposalPriceWasteUnitType
                      ? "/ " + formValue.wasteInfo.disposalPriceWasteUnitType.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.disposalReportInfo && formValue.disposalReportInfo.disposalWayType
                      ? formValue.disposalReportInfo.disposalWayType.name
                      : "" | blankText
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>有害物質名</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    v-for="(harmfulItem, itemIndex) in formValue.harmfulList"
                    :key="`hmfl-item-${itemIndex}`"
                    class="l-flex start"
                  >
                    {{ harmfulItem.name }}
                  </div>
                  <div v-if="formValue.harmfulList.length === 0">-</div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>連絡番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <template v-for="contactInfo in formValue.contactNoInfo">
                    <div class="l-area" :key="contactInfo.id">
                      {{ contactInfo.contactNo }}
                    </div>
                  </template>
                  <div v-if="formValue.contactNoInfo.length === 0">-</div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>備考</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    class="l-area"
                    v-for="taInfo in formValue.remarksInfo"
                    :key="'ta' + taInfo.id"
                  >
                    {{ taInfo.detail }}
                  </div>
                  <div v-if="formValue.remarksInfo.length === 0">-</div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>WDS</label>
                </div>
                <div
                    class="c-inputWrap__items"
                    v-if="formValue.routeInfo.wdsFileName"
                >
                  <a
                      class="c-text text-link c-entrustText__text"
                      @click="
                      downloadWDS(
                        formValue.routeInfo.routeId,
                        formValue.routeInfo.wdsFileName
                      )
                    "
                  >
                    {{ formValue.routeInfo.wdsFileName }}
                  </a>
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>

              <div
                v-for="manifestLabel in formValue.labelInfo.manifestLabels"
                :key="`${manifestLabel.labelHeader}-${manifestLabel.labelId}`"
                class="c-inputWrap"
              >
                <div class="c-inputWrap__label">
                  <label>{{ manifestLabel.labelHeader }}</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ manifestLabel.labelValue }}
                  {{ manifestLabel.labelUnit }}
                </div>
              </div>
            </div>
            <!-- / c-accordion__body -->
          </div>
          <!-- / c-accordion -->
        </div>
      </div>
      <!-- /.l-contents -->

      <!-- 運搬終了報告 -->
      <template v-if="formValue.collectReportList.length > 0">
        <CollectReportList
          v-for="(list, index) in formValue.collectReportList"
          :key="`collectReport-${index + 1}`"
          :index="index + 1"
          :total="formValue.collectReportList.length"
          :wasteType="formValue.wasteType"
          :list="list"
          :type="disposalReportDisplayType.AfterReport"
        />
      </template>
      <template v-else>
        <CollectReportList :index="0" :total="0" :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" />
      </template>

      <!-- 処分終了報告 -->
      <template v-if="formValue.disposalReportInfo">
        <InterDisposalList
          :wasteType="formValue.wasteType"
          :list="formValue.disposalReportInfo"
          :type="disposalReportDisplayType.AfterReport"
          :disposalRegistDate="formValue.disposalReportInfo.disposalReportDate ? formValue.disposalReportInfo.disposalReportDate : ''"
        />
      </template>
      <template v-else>
        <InterDisposalList :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" :finalDisposalRegistDate="''" />
      </template>

      <!-- 最終処分終了報告 -->
      <template v-if="formValue.finalDisposalReportInfo">
        <DisposalReportList
          :wasteType="formValue.wasteType"
          :list="formValue.finalDisposalReportInfo"
          :type="disposalReportDisplayType.AfterReport"
        />
      </template>
      <template v-else>
        <DisposalReportList :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" />
      </template>
    </div>

    <FloatButtons>
      <div class="left">
        <div class="c-btn secondary small back" @click="backHandler">一覧へ戻る</div>
      </div>
      <div class="center">
        <div class="c-btn secondary small" v-if="!isDisposer" @click="cancelConfirmHandler" :class="{ disabled: isJwnetReferring }">取消</div>
      </div>
    </FloatButtons>

    <!-- 取消確認モーダル -->
    <div class="c-modal" v-show="isCancelConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを取り消します 。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isCancelConfirmModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click.once="cancelHandler">OK</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import route from "../mixins/route";
import idToName from "../mixins/idToName";
import textarea from "../mixins/textarea";
import harmfulType from "../mixins/harmfulType";
import validate from "../mixins/validate";
import formatEditData from "../mixins/formatEditData";
import { saveAs } from "file-saver";
import { API_URL, DOMAIN } from "@/const";
import CollectReportList, {
  CollectReportDisplayType,
} from "./CollectReportList.vue";
import InterDisposalList, {
  InterDisposalReportDisplayType,
} from "./InterDisposalList.vue";
import DisposalReportList, {
  DisposalReportDisplayType,
} from "./DisposalReportList.vue";
import ManifestProgressBar from "./ManifestProgressBar";
import ToastMessage from "@/components/parts/ToastMessage";
import FloatButtons from "@/components/layout/FloatButtons";
import commonApi from "@/mixin/commonApi";

export default {
  name: "manifest-detail-proxy-reserve-issue",
  props: {
    manifestId: {
      type: Number,
    },
    formValue: {
      type: Object,
      required: true,
    },
    afterUpdateFunc: {
      type: Function,
      required: true,
    },
  },
  components: {
    FloatButtons,
    ToastMessage,
    ManifestProgressBar,
    CollectReportList,
    InterDisposalList,
    DisposalReportList,
  },
  mixins: [
    validation,
    callApi,
    route,
    idToName,
    textarea,
    harmfulType,
    validate,
    formatEditData,
    commonApi,
  ],

  data() {
    return {
      backPath: null,
      isApproveConfirmModalShow: false,
      isDenyReasonModalShow: false,
      isCompleteConfirmModalShow: false,
      reportFixApprove: null,
      isApproved: true,
      denyReason: "",
      errMsgs: [],
      waitCancelPendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_DELETE_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_DELETE_PENDING,
      ],
      waitPendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORT_FIX_PENDING,
      ],
      requestPendingStatus: DOMAIN.MANIFEST_STATUS.MANIFEST_FIX_PENDING,
      finalDisposalReported: DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED,
      isDeliveryDeadline: false,
      isDeliveryExpired: false,
      isDeliveryReportDeadline: false,
      isDeliveryReportExpired: false,
      isInProgress: false,
      isCompleted: false, // 一覧画面遷移用
      collectReportDisplayType: CollectReportDisplayType,
      disposalReportDisplayType: DisposalReportDisplayType,
      interDisposalReportDisplayType: InterDisposalReportDisplayType,
      isCancelConfirmModalShow: false,
      toastMsg: "",
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isDisplayManifestFixApproveModal: false,
      manifestEditRequest: {},
    };
  },
  computed: { },
  methods: {
    setRouteLabelStr() {
      // ルート用
      const r = this.formValue.routeInfo;
      this.routeCompanyName.generateStore = r.generateStore.name;
      this.routeCompanyName.collectCompany =
        r.collectCompanys.length != 0 ? r.collectCompanys[0].name : "";
      this.routeCompanyName.disposalSite = r.disposalSite
        ? r.disposalSite.name
        : "";
    },

    // WDSファイルダウンロード
    downloadWDS(wasteInfoId, wdsFileName) {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 戻る押下
    backHandler() {
      if (this.backPath) {
        this.$router.push(this.backPath);
      } else {
        this.$router.push('/manifests/all');
      }
    },

    // 取消押下
    cancelConfirmHandler() {
      this.isCancelConfirmModalShow = true;
    },

    // 取消、モーダル内のOK押下
    cancelHandler() {
      this.errMsgs = [];

      axios
          .patch(API_URL.MANIFEST.STATUS_UPDATE, {
            manifestIdList: [{ manifestId: this.manifestId }],
            manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE,
          })
          .then(() => {
            this.isCancelConfirmModalShow = false;
            this.backHandler();
          })
          .catch((err) => {
            this.isCancelConfirmModalShow = false;
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    init() {
      this.backPath = this.$route.query.transitionSource;
      this.getRouteListApi(this.formValue.routeInfo.generateStore.id, false);
      this.setRouteLabelStr();
      // 初期表示に必要なAPIを呼ぶ
      this.getMemberOptApi(this.formValue.routeInfo.generateStore.id);
      this.getWasteUnitApi();
      this.getConfirmMemberApi(this.formValue.routeInfo.routeId);
      this.getHarmfulTypesApi();
      this.getShapeApi();

      if(this.formValue.manifestInfo.manifestNo) {
        this.getIsJwnetReffering(this.formValue.manifestInfo.manifestNo)
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>

