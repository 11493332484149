import axios from "axios";
import {API_URL} from "@/const";
import {saveAs} from "file-saver";

export default {
    methods: {
        // マニフェスト添付ファイルダウンロード
        downloadManifestAttachedFileApi(manifestId, fileName) {
            return axios
                .get(API_URL.MANIFEST.FILE_DOWNLOAD + manifestId, {
                    responseType: "blob",
                })
                .then((res) => {
                    const blob = new Blob([res.data], {
                        type: res.data.type,
                    });

                    saveAs(blob, fileName);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
}